<template>
  <div>
    <title>SIM INVENTORY ~ ADD DETAILS ITEM OUTGOING LIST DATA</title>
    <section class="content-header">
      <h1>
        Add Detailed Item Outgoing List Data
        <br />
        <h4>
          Please enter the transaction data for the detailed list of items outgoing
        </h4>
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Item Outgoing List Transaction details</li>
      </ol>
    </section>
    <section class="content">
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>
      
      <div class="row">
        <div class="col-md-12">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
              <i class="fa fa-save"></i>
              <h3 class="box-title">Save Detailed Item Outgoing List Data</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="row">
                  <div class="col-xs-9">
                    <label for="exampleInputEmail1">Outgoing Number </label>
                    <input
                      type="text"
                      v-model="isidata.outgoingno"
                      autocomplete="off"
                      class="form-control"
                      placeholder="No. Outgoing"
                    />
                    <input
                      type="hidden"
                      v-model="isidata.idpenerimaand"
                      autocomplete="off"
                      class="form-control"
                    />
                  </div>
                  <div class="col-xs-3">
                    <label for="exampleInputEmail1">Action</label>
                    <br />
                    <button
                      type="button"
                      @click="getoutgoing()"
                      class="btn btn-info"
                    >
                      Search
                    </button>
                  </div>
                </div>
                <br />
                <div class="form-group">
                  <label for="exampleInputEmail1">Order Number </label>
                  <input
                    type="text"
                    readonly
                    v-model="isidata.odernumb"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Notes </label>
                  <input
                    type="text"
                    v-model="isidata.catatan"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Qty </label>
                  <input
                    type="number"
                    v-model="isidata.jml"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="box-footer">
                <button
                  @click="saveData()"
                  type="button"
                  class="btn btn-primary"
                > <i class="fa fa-save"></i>
                  Save
                </button>
                &nbsp;
                <router-link to="/outgoing">
                  <button class="btn btn-success"><i class="fa fa-angle-double-left"></i> Back</button>
                </router-link>
              </div>
            </form>
          </div>
        </div>
    
      </div>
    </section>
  </div>
</template>
<script>
import swal from "sweetalert";
var axios = require("axios");
export default {
  name: "addmasterdata",
  data() {
    return {
      list_paged: [],
      loading: false,
      isidata: {
        outgoingno: "",
        odernumb: "",
        idpenerimaand: "",
        catatan: "",
        jml: ""
      }
    };
  },
  created() {
    this.isidata.idpenerimaand = this.$route.params.id;
  },
  methods: {
    getoutgoing() {
      this.loading = true;
      const urlAPIget =
        this.$apiurl +
        "outgoing_h/getoutgoing_hbyoutgoing_number?outgoing_number=" +
        this.isidata.outgoingno;
      const tokenlogin = sessionStorage.getItem("token");
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          if (resp.data.pesan == "Data not found") {
            swal({
              icon: "warning",
              title: "Warning",
              text: "Sorry, Outgoing Number not found",
              showConfirmButton: false
            });
            this.isidata.odernumb = "";
          } else {
            this.isidata.outgoingno = resp.data.data.outgoing_number;
            this.isidata.odernumb = resp.data.data.order_number;
            this.loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },

    async saveData() {
      this.loading = true;
      var noout = this.isidata.outgoingno;
      var numbord = this.isidata.odernumb;
      var catat = this.isidata.catatan;
      var jmlp = this.isidata.jml;

      if (noout == "" || numbord == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Outgoing Number / Order Number can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (catat == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Notes can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (jmlp == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Qty can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        // const tokenlogin = localStorage.getItem("token");
        const kodeuser = sessionStorage.getItem("kodeuser");
        const paramdata = {
          outgoing_number: this.isidata.outgoingno,
          id_penerimaan: this.isidata.idpenerimaand,
          notes: this.isidata.catatan,
          qty: this.isidata.jml,
          kode_user: kodeuser
        };

        const tokenlogin = sessionStorage.getItem("token");
        const urlAPIsavedata = this.$apiurl + "outgoing_d/saveoutgoing_d";

        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        axios
          .post(urlAPIsavedata, paramdata, { headers })
          .then((resp) => {
            if (resp.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Save data successfully",
                showConfirmButton: false
              });
              this.loading = false;
              //   this.$router.push({ name: "outgoingheader" });
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Save data failed",
                showConfirmButton: false
              });
              this.loading = false;
              //   this.$router.push({ name: "outgoingheader" });
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            console.log(err);
            this.loading = false;
          });
      }
    }
  }
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
